import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import img from '../../assets/banner-pics/marija-blog.webp'

const MarijaBlog = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
        </div>
      </ParallaxProvider>
      <div className="max-w-4xl mx-auto p-5 pt-5">
        <h1 className="text-xl md:text-3xl font-bold text-center mb-6">{t('marijaBlog.marijaTitle')}</h1>
        <p className="mb-4 text-justify">{t('marijaBlog.marijaIntroduction')}</p>
        <p className="mb-4 text-justify">{t('marijaBlog.marijaTrainingBenefits')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('marijaBlog.marijaBenefitsTitle')}</h2>
        <ul className="list-disc list-inside mb-4">
          {Object.entries(t('marijaBlog.marijaBenefitsList', { returnObjects: true })).map(([key, value]) => (
            <li key={key}>{value}</li>
          ))}
        </ul>
        <p className="mb-4 text-justify">{t('marijaBlog.marijaConclusion')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('marijaBlog.marijaKeyFeaturesTitle')}</h2>
        <ul className="list-disc list-inside mb-4 text-justify">
          {Object.entries(t('marijaBlog.marijaKeyFeaturesList', { returnObjects: true })).map(([key, value]) => (
            <li key={key}>{value}</li>
          ))}
        </ul>
        <footer className="mt-8 border-t pt-4">
          <p className="text-center text-sm text-gray-600">{t('marijaBlog.marijaAuthor')}</p>
        </footer>
      </div>
    </div>
  );
};

export default MarijaBlog;
