import React, { createContext, useEffect, useState } from 'react';
import { getPosts } from '../sanity/sanity-utils';

export const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getPosts();
        const data = await response;
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []); // Empty dependency array to trigger the effect only on the first load

  return (
    <PostContext.Provider value={posts}>
      {children}
    </PostContext.Provider>
  );
};
