import React from 'react';
import { useParams } from 'react-router-dom';
import data from '../../static-data/programs';
import './ProgramPage.css'
import { Parallax } from 'react-parallax';

const ProgramPage = () => {
  const params = useParams();
  const { programId } = params;
  const programData = data.find((el) => el.id === parseInt(programId, 10));
  console.log(programData, 'data')

  return (
    <div className="program-page-wrapper">
      <Parallax strength={200} bgImage={programData.img}>
        <div className="program-page-banner" style={{ height: '45vh', width: '100vw', }}>
          <h2 className='text-white' style={{ fontFamily: 'bold-italic' }}>{programData.title}</h2>
        </div>
      </Parallax>
      <div className='program-tips'>
        <span>{programData.goodToKnows.age} godina</span>
        <span className='dot'>|</span>
        <span>{programData.goodToKnows.duration} Minuta</span>
        <span className='dot'>|</span>
        <span>{programData.goodToKnows.weekly} Termina sedmično</span>
      </div>
      <div className="program-page-content">
        <h2>WHAT IS {programData.title}?</h2>
        {/* <img className="program-page-img" src={programData.img} alt={`image for program number ${programId}`} /> */}
        <div className="program-page-desc">
          {programData.description}
        </div>
      </div>
      <div className="program-page-gallery">
        <img className='gallery-img' src="" alt="img" />
        <img className='gallery-img' src="" alt="img" />
        <img className='gallery-img' src="" alt="img" />
        <img className='gallery-img' src="" alt="img" />
        {/* <img className='gallery-img' src="" alt="img" /> */}
      </div>
    </div>
  );
};

export default ProgramPage;
