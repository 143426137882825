import React, { useState } from 'react';
import { FiArrowUp } from 'react-icons/fi'
import './backTop.css'

const BackToTopButton = () => {
  // State to track the visibility of the button
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the scroll event and toggle the button visibility
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 0);
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Register the scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <button
      className={`back-to-top ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <FiArrowUp />
    </button>
  );
};

export default BackToTopButton
