import React from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import lgZoom from 'lightgallery/plugins/zoom';
import { useTranslation } from 'react-i18next';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import img from '../../assets/images/gallery/interior/15.webp';
import './Gallery.css';

// Function to import all images from a folder
const importImages = (context) => context.keys().map(context);

const interiorImages = importImages(require.context('../../assets/images/gallery/interior', false, /\.(webp|jpg|png)$/));
const exteriorImages = importImages(require.context('../../assets/images/gallery/exterior', false, /\.(webp|jpg|png)$/));
const garageImages = importImages(require.context('../../assets/images/gallery/garage', false, /\.(webp|jpg|png)$/));

const Gallery = () => {
  const { t } = useTranslation();

  const renderGallery = (images, title) => (
    <div className="gallery-section">
      <h2 className="text-center text-xl md:text-2xl font-bold mt-6">{title}</h2>
      <LightGallery
  plugins={[lgZoom]}
  elementClassNames="gallery-grid mt-4"
  speed={500}
  zoomFromOrigin={true}
  options={{
    getCaptionFromTitleOrAlt: false, // Disable captions from alt or title
  }}
>
  {images.map((src, index) => (
    <a key={index} href={src} className="gallery-item">
      <img src={src} alt="" /> {/* Ensure alt is empty to avoid fallback */}
    </a>
  ))}
</LightGallery>
    </div>
  );

  return (
    <div className="gallery-wrapper">
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
          <div className="text-xl md:text-4xl font-bold-italic block">{t('galleryBanner')}</div>
        </div>
      </ParallaxProvider>

      <div className="gallery-content px-4">
        {renderGallery(interiorImages, t('Interior'))}
        {renderGallery(exteriorImages, t('Exterior'))}
        {renderGallery(garageImages, t('Garage'))}
      </div>
    </div>
  );
};

export default Gallery;
