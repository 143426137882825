import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import './NewsCard.css';

const NewsCard = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`novosti/${data.slug}`);
  };

  const convertIsoToCustomFormat = (isoString) => {
    const date = new Date(isoString);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[date.getUTCMonth()];

    return `${day}. ${monthName}. ${year}.`;
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <Link to={`/`} className="news-card">
      <div className="news-card-image-container">
        <img className="news-card-image" src="https://cdn.oxygenmag.com/wp-content/uploads/2020/11/functional-exercise.jpg" alt="news" />
      </div>
      <div className="news-card-content">
        <div>
          <h2 className="news-card-title">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat, officia.</h2>
          <p className="news-card-description">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum odio possimus delectus esse vero voluptatem dignissimos, consectetur, doloremque laudantium at sit! Quidem asperiores odio delectus consectetur qui sunt molestias voluptatum. Possimus nesciunt facilis labore alias repellat libero ullam magnam provident.</p>
        </div>
        <div className="news-card-button-container">
          <button className="learn-more-button">Saznaj Više</button>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
