import React from 'react';
import { useTranslation } from 'react-i18next';
import './AdvantagesNew.css';

const AdvantagesNew = () => {
  const { t } = useTranslation();

  return (
    <div className='min-h-screen flex flex-col md:flex-row bg-gray-main bg-opacity-5'>
      <div className='adv-bg md:w-1/2 h-96 md:h-auto flex md:flex-row justify-center items-center outline-5 outline-black'>
        <div className='text-gray-50 absolute z-10 text-4xl text-center'>{t('advantagesTitle')}</div>
      </div>
      <div className='md:w-1/3 md:h-auto h-max py-12 gap-12 flex flex-col justify-center items-center m-auto w-2/3'>
        <div className='flex flex-col justify-center items-start'>
          <div className='flex flex-row items-center gap-4 text-left pb-4'><div className='text-4xl'>🏋️</div><div className='text-green-600'>{t('weightTraining')}</div></div>
          <p className='text-gray-700 text-sm pl-3'>{t('weightTrainingDesc')}</p>
        </div>
        <div className='flex flex-col justify-center items-start'>
          <div className='flex flex-row items-center gap-4 text-left pb-4'><div className='text-4xl'>🏃‍♀️</div><div className='text-green-600'>{t('generalFitness')}</div></div>
          <p className='text-gray-700 text-sm pl-3'>{t('generalFitnessDesc')}</p>
        </div>
        <div className='flex flex-col justify-center items-start'>
          <div className='flex flex-row items-center gap-4 text-left pb-4'><div className='text-4xl'>🤸‍♀️</div><div className='text-green-600'>{t('functionalTraining')}</div></div>
          <p className='text-gray-700 text-sm pl-3'>{t('functionalTrainingDesc')}</p>
        </div>
      </div>
    </div>
  )
}

export default AdvantagesNew;
