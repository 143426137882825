import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const BlogCard = ({ img, author }) => {
  const navigate = useNavigate()
  const goToBlog = () => {
    window.scroll(0, 0)
    navigate(`/blog/${author}`)
  }
  const { t } = useTranslation()
  return (
    <div onClick={goToBlog} className="cursor-pointer bg-white w-10/12 md:w-3/5 shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out m-auto" style={{ height: '100%' }}>
      <div className="w-full h-40 overflow-hidden">
        <img className="w-full h-full object-cover" src={img} alt="." />
      </div>
      <div className="p-5 h-1/5 flex flex-col justify-between">
        <h3 className="text-gray-900 font-bold text-md md:text-lg mb-1">
          {t(`${author}Blog.${author}Title`)}
        </h3>
      </div>
    </div>
  )
}

export default BlogCard