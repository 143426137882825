import React from 'react';
import TrainerList from '../../components/TrainersList/TrainersList';
import './Team.css'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import img from '../../assets/images/team-bg.webp'
import { useTranslation } from 'react-i18next';

const Team = () => {

  const { t } = useTranslation()

  return (
    <div className='team-wrapper'>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
          <div className="text-xl md:text-4xl font-bold-italic block">{t('teamBanner')}</div>
        </div>
      </ParallaxProvider>
      <TrainerList />
    </div>
  );
};

export default Team;
