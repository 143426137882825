import React from 'react'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'
import img from '../assets/banner-pics/blog.webp'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BlogCard from '../components/BlogCard.jsx'
import marija from '../assets/banner-pics/marija-blog.webp'
import aleksa from '../assets/banner-pics/aleksa-blog.webp'
import jovan from '../assets/banner-pics/jovan-blog.webp'
import ivan from '../assets/banner-pics/ivan-blog.webp'

const Blogs = () => {
  const { t } = useTranslation()
  return (
    <div className='team-wrapper'>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
          <div className="text-xl md:text-4xl font-bold-italic block uppercase">{t('blogNav')}</div>
        </div>
      </ParallaxProvider>
      <div className='flex flex-col gap-8 py-8 m-auto w-screen'>
        <BlogCard img={marija} author="marija" />
        <BlogCard img={aleksa} author="aleksa" />
        <BlogCard img={jovan} author="jovan" />
        <BlogCard img={ivan} author="ivan" />
      </div>
    </div>
  )
}

export default Blogs