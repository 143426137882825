import React from 'react';
import { useParams } from 'react-router-dom';

const Vjezba = () => {
  // Array of exercises with names and shared video link
  const exercises = [
    { name: 'back-row', videoId: 'e02OJb7_5zo' }, // done
    { name: 'biceps-curl', videoId: 'StLLRhIFwvU' }, // done
    { name: 'unilateral-biceps-curl', videoId: '-Dm9qazuxlw' }, // done
    { name: 'hack-squat', videoId: 'f_Z1MX9ISVM' }, // done
    { name: 'shoulder-press-3', videoId: '8h5lWeHkVTc' }, // done
    { name: 'lat-pulldown', videoId: 'YcNDtxguzEs' }, // done
    { name: 'seated-calf-raise', videoId: 'mJjQ4_YhDxM' }, // done
    { name: 'reversed-lat-pulldown', videoId: 'PwId3J2QA7M' }, // done
    { name: 'lat-pulldown-2', videoId: 'ekjpu8cUC3A' }, // done
    { name: 'abdominal-crunch', videoId: 'I_MW8AtM0Gs' }, // done
    { name: 'chest-press', videoId: 'Ul8hzb3Z5l8' }, // done
    { name: 'back-extension', videoId: '9_YoEa2K3Bw' }, // done
    { name: 'adductor', videoId: 'HjKyaQmpZh4' }, // done
    { name: 'abductor', videoId: 'IM4OG1KJzwo' }, // done
    { name: 'standing-abductor', videoId: 'eLTPIMyOIiY' }, // done
    { name: 'shoulder-press', videoId: 'we1uCkrGZsk' }, // done
    { name: 'shoulder-press-2', videoId: 'dC4Vw78jAsM' }, // done
    { name: 'assisted-dip', videoId: 'm8L6VXEkYjk' }, // done
    { name: 'leg-extension', videoId: 'ZVLhM1k3wtQ' }, // done
    { name: 'abs-crunch', videoId: 'h3QRp6XNnDA' }, // done
    { name: 'triceps-dip', videoId: 'PS6mIOf0AOk' }, // done
    { name: 'assisted-pull-up', videoId: 'V_3NPWnsQfo' }, // done
    { name: 'hamstring-curl', videoId: '3CoFBqrUJ-U' }, // done
    { name: 'multigrip-back-row', videoId: 'Byuonck1rBw' }, // done
    { name: 'butterfly', videoId: 'PJ9q6a2u3lY' }, // done
    { name: 'hip-thrust', videoId: 'I0NxJYOMFMM' }, // done
    { name: 'incline-chest-press', videoId: '2U720Mx_6Gk' }, // done
    { name: 'rear-delt-fly', videoId: '80He89BFPU8' }, // done
    { name: 'pec-fly', videoId: 'ZbkOjnizZoE' }, // done
    { name: 'leg-press', videoId: '26VkwdBorjg' }, // done
    { name: 'decline-chest-press', videoId: '-BJ5P2REjW8' }, // done
  ];

  // Get the exercise parameter from the route
  const { exercise } = useParams();

  // Find the matching exercise by name
  const selectedExercise = exercises.find((e) => e.name === exercise);

  // Handle case where the exercise doesn't exist
  if (!selectedExercise) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <div className="text-center uppercase text-2xl text-red-500">
          Exercise not found
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center pt-40 bg-black">
      {/* Title */}
      <div
        className="w-full text-center uppercase text-xl font-semibold text-white bg-red-500 pt-8 pb-3 mb-8"
        style={{
          letterSpacing: "0.05em",
        }}
      >
        {selectedExercise.name
          .replace(/-\d+$/, '')
          .replace(/-/g, ' ')}
      </div>



      {/* YouTube Shorts Embed with Native Aspect Ratio (9:16) */}
      <div className="w-full max-w-xs relative" style={{ paddingBottom: '177.77%' /* 9:16 aspect ratio */ }}>
        <div className="absolute top-0 left-0 w-full h-2/3">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${selectedExercise.videoId}?autoplay=1&loop=1&playlist=${selectedExercise.videoId}&mute=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={selectedExercise.name}
          />
        </div>
      </div>
      <div>
        {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Expedita, quidem quis amet nostrum dolore corporis qui iste fugit fuga labore? */}
      </div>
    </div>
  );
};

export default Vjezba;
