import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1>404</h1>
      <div>{t('notFoundMessage')}</div>
      <Link to="/" style={{marginTop: '1rem'}} className='learn-more-button'>{t('goBack')}</Link>
    </div>
  )
}

export default NotFound