import { defineConfig } from 'sanity';
import { deskTool } from 'sanity/desk'
import schemas from '../app/sanity/schemas'

const config = defineConfig({
    projectId: 'fsqhzy1o',
    dataset: 'production',
    title: 'Gym Website',
    apiVersion: '2023-05-15',
    basePath: '/admin',
    plugins: [deskTool()],
    schema: { types: schemas }
})

export default config