import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // Load translation files
  .use(HttpBackend)
  // Detect user language
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
  // Initialize i18next
  .init({
    fallbackLng: 'me', // Default language to use if detection fails
    debug: true, // Set to false in production
    detection: {
      // Order and from where user language should be detected
      order: ['localStorage', 'queryString', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      // Keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      // Cache user language on
      caches: ['localStorage', 'cookie'],
      // Optional expire and domain for cookie
      cookieMinutes: 10080, // 7 days
      cookieDomain: window.location.hostname,
      // Only detect languages that are in the whitelist
      checkWhitelist: true,
    },
    interpolation: {
      // Escape values to avoid XSS attacks
      escapeValue: false,
    },
    backend: {
      // Path to the translation files
      loadPath: '/locales/{{lng}}/translation.json',
    },
    whitelist: ['en', 'me', 'ru'], // List of allowed languages
  });

export default i18n;
