import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import img from '../../assets/banner-pics/aleksa-blog.webp'

const AleksaBlog = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
        </div>
      </ParallaxProvider>
      <div className="max-w-4xl mx-auto p-5 pt-5">
        <h1 className="text-xl md:text-3xl font-bold text-center mb-6">{t('aleksaBlog.aleksaTitle')}</h1>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaIntroductionTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaIntroduction')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaPowerTrainingTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaPowerTraining')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaEnduranceTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaEndurance')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaFlexibilityTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaFlexibility')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaTrainingTipsTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaTrainingTips')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('aleksaBlog.aleksaConclusionTitle')}</h2>
        <p className="mb-4 text-justify">{t('aleksaBlog.aleksaConclusion')}</p>
        <footer className="mt-8 border-t pt-4">
          <p className="text-center text-sm text-gray-600">{t('aleksaBlog.aleksaAuthor')}</p>
        </footer>
      </div>
    </div>
  );
};

export default AleksaBlog;
